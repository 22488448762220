<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite min_h500">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_title" class="subTitle">{{$t('referTree.title')}}</p>
        </div>
        <div class="RightBox">
          <div>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade0')}}<span class='px-2 mx-1' style="background:#fdcfcf"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade1')}}<span class='px-2 mx-1' style="background:#f08b8b"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade2')}}<span class='px-2 mx-1' style="background:#c7733a"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade3')}}<span class='px-2 mx-1' style="background:#f2f484"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade4')}}<span class='px-2 mx-1' style="background:#62d689"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{ $t('grade.grade5') }}<span class='px-2 mx-1' style="background:#74dae1"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade6')}}<span class='px-2 mx-1' style="background:#6966ff"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('grade.grade7')}}<span class='px-2 mx-1' style="background:#a046d4"></span>
            </span>
            <span class='px-2 mx-1 color-white'>
              {{$t('common.nonSale')}}<span class='px-2 mx-1' style="background:#332b2e"></span>
            </span>
          </div>
          <div id="tree" ref="tree"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import OrgChart from '@balkangraph/orgchart.js'
const CryptoJS = require("crypto-js");
export default {
  data() {
    return {
      nodes: [],
    }
  },
  mounted() {
    this.initChart();
    this.GetNode();
  },
  methods: {
    initChart() {
      OrgChart.templates.olivia = Object.assign({}, OrgChart.templates.ana);
      OrgChart.templates.olivia.field_0 =
          '<text data-width="135" width="135" style="font-size: 18px;" fill="#000" x="100" y="30">{val}</text>';
      OrgChart.templates.olivia.field_1 =
          '<text data-width="135" style="font-size: 16px;" fill="#000" x="100" y="60">{val}</text>';
      OrgChart.templates.olivia.field_2 =
          '<text data-width="135" style="font-size: 16px;" fill="#000" x="100" y="90">{val}</text>';
      OrgChart.templates.olivia.defs =
          '<linearGradient id="{randId}" x1="0%" y1="0%" x2="0%" y2="100%"><stop offset="0%" style="stop-color:#fff;stop-opacity:1" />'
          + '<stop offset="100%" style="stop-color:#eee;stop-opacity:1" /></linearGradient>';
      OrgChart.templates.olivia.node =
          '<rect fill="url(#{randId})" x="0" y="0" height="{h}" width="{w}" stroke-width="1" stroke="#aeaeae" rx="7" ry="7"></rect>';
      OrgChart.templates.olivia.img_0 =
          '<clipPath id="{randId}"><circle cx="50" cy="60" r="40"></circle></clipPath>'
          + '<image preserveAspectRatio="xMidYMid slice" clip-path="url(#{randId})" xlink:href="{val}" x="10" y="20" width="80" height="80"></image>';
      OrgChart.templates.olivia.nodeMenuButton =
          '<g style="cursor:pointer;" transform="matrix(1,0,0,1,225,105)" data-ctrl-n-menu-id="{id}">'
          + '<rect x="-4" y="-10" fill="#000000" fill-opacity="0" width="22" height="22">'
          + '</rect><circle cx="0" cy="0" r="2" fill="#AEAEAE"></circle><circle cx="7" cy="0" r="2" fill="#AEAEAE">'
          + '</circle><circle cx="14" cy="0" r="2" fill="#AEAEAE"></circle>'
          + '</g>';
    },
    GetNode() {
      this.$axios.post('/member/organization/GetNode', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res);
                this.nodes = body.list;
                this.checkNode()
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => window.location.href = "/sign/login"
                )
              }
            } else {
              this.loading = false;
            }
          }
      )
    },
    checkNode: function () {
      for (var i = 0; i < this.nodes.length; i++) {
        const node = this.nodes[i];

        if (node.grade <= 0 && node.balance <= 0) {
          node.tags = ["STAR_99"];
          node[this.$i18n.t('referTree.grade')] = this.$i18n.t('common.nonSale');
        } else {
          node.tags = ["STAR_" + node.grade];
          node[this.$i18n.t('referTree.grade')] = this.$i18n.t('grade.grade' + node.grade);
        }

        node[this.$i18n.t('referTree.id')] = node.id;
        node[this.$i18n.t('referTree.plan')] = node.plan === 0 ? this.$i18n.t('common.nonSubscribe')
            : "Plan " + node.plan;
        node[this.$i18n.t('referTree.price')] = node.balance;
        node[this.$i18n.t('referTree.autoBot')] = this.$i18n.t('referTree.autoBot') + ' ' + (node.plan >= 4 ? this.$i18n.t('common.subscribe') : this.$i18n.t('common.nonSubscribe'));

        delete node.grade;
        delete node.plan;
        delete node.balance;
      }

      this.chart = new OrgChart(document.getElementById("tree"), {
        nodeBinding: {
          img_0: "img",
          field_0: this.$i18n.t('referTree.id'),
          field_1: this.$i18n.t('referTree.grade'),
          field_2: this.$i18n.t('referTree.autoBot'),
        },
        template: "olivia",
        enableSearch: true,
        miniMap: true,
        collapse: {
          level: 3
        },
        nodes: this.nodes
      });

      this.show = false
    }
  }
}
</script>

<style>
.node.STAR_0 rect {fill: #fdcfcf;}
.node.STAR_1 rect {fill: #f08b8b;}
.node.STAR_2 rect {fill: #c7733a;}
.node.STAR_3 rect {fill: #f2f484;}
.node.STAR_4 rect {fill: #62d689;}
.node.STAR_5 rect {fill: #74dae1;}
.node.STAR_6 rect {fill: #6966ff;}
.node.STAR_7 rect {fill: #a046d4;}
.node.STAR_99 rect {fill: #332b2e;}
.node.STAR_99 text {fill: #fff;}
</style>

<style lang="scss" scoped>
.intxt01 {
  margin-top: -8px;
}

.show-mobile-menu {
  right: 0;
}

.hide-mobile-menu {
  right: 1;
}

@media (min-width: 300px) {
  .hide {
    display: none !important;
  }
  .small-add {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (min-width: 700px) {
  .hide {
    //   display: flex !important;
    margin: auto;
    //   display: block !important;
    display: table-cell !important;
    text-align: center;
  }
  .small-add {
    max-width: 100%;
  }
}

#users {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  margin: 12px 0px;
  border-radius: 8px;
  overflow: hidden;
}

select option {
  margin: 40px;
  background: #1f2232;
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

@media screen and (max-width: 550px) {
  .intxt01 {
    width: 70px;
  }
}

</style>
