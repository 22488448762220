<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite min_h500">
        <div class="search-container">
          <div class="row">
            <div class="col-sm-12">
              <div class="title">{{$t('refererList.title')}}</div>
            </div>
          </div>
          <div class="row mt-3">
              <label for="searchId" class="col-sm-2 col-form-label">{{$t('refererList.searchId')}}</label>
              <div class="col-sm-4">
                <div class="input-group">
                  <input type="text" class="form-control" id="searchId" v-model="searchId"
                         :placeholder="$t('refererList.searchHolder')" @keyup="searchById">
                </div>
              </div>
          </div>
        </div>
        <div class="ref-list">
          <div class="ref-list-history ng-star-inserted">
            <div class="ng-star-inserted" v-for="(item,index) in list " :key="index">
              <RefferElement :item="item" :supportList="support_list"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import RefferElement from "@/components/main/reffer-list/RefferElement.vue";

const CryptoJS = require("crypto-js");

export default {
  components: {
    RefferElement
  },
  data() {
    return {
      list: [],
      originList: [],
      support_list: [],
      searchId: ''
    }
  },
  mounted() {
    this.GetRefferList();
    this.GetSupportList();
  },
  methods: {
    GetSupportList() {
      this.$axios.post('/member/reffer/GetSupportList', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.support_list = body.list;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    GetRefferList() {
      this.$axios.post('/member/reffer/GetList', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.originList = body.list;
                this.list = body.list;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    searchById() {
      if (this.searchId === '') {
        this.list = this.originList;
      } else {
        this.list = this.originList.filter(item => item.id.includes(this.searchId))
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.search-container {
  margin-top: 10px;
  padding: 10px;
}

.search-container .title {
  font-size: 2em;
  color: white;
  font-weight: bold;
}

.search-container .col-form-label {
  color: white;
  font-size: 1.5em;
}

.input-group input {
  height: auto;
}

.ref-list {
  margin-top: 10px;
}

.ref-list-history {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(5, 1fr)
}

@media (max-width: 300px) {
  .ref-list-history {
    grid-template-columns: repeat(1, 1fr)
  }
}

@media (min-width: 301px) and (max-width: 700px) {
  .ref-list-history {
    grid-template-columns: repeat(2, 1fr)
  }
}

@media (min-width: 701px) and (max-width: 1000px) {
  .ref-list-history {
    grid-template-columns: repeat(3, 1fr)
  }
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .ref-list-history {
    grid-template-columns: repeat(4, 1fr)
  }
}

@media (min-width: 1201px) and (max-width: 1500px) {
  .ref-list-history {
    grid-template-columns: repeat(4, 1fr)
  }
}
</style>
