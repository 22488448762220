<template>
  <div class="gBoxN_01 bg-white motion col-md">
    <p class="tTitle card_bg5">{{ item.id }}</p>
    <div class="card_bottom">
      <p class="tTitle1">{{$t('refererElement.date')}}
        <span class="ng-star-inserted">{{ item.date }}</span>
      </p>
      <p class="tTitle1">{{$t('refererElement.plan')}}
        <span class="ng-star-inserted">{{ $checkPlan(item.plan) }}</span>
      </p>
      <p class="tTitle1">
        {{$t('refererElement.level')}} <span class="ng-star-inserted">{{ $checkGrade(item.grade) }}</span>
      </p>
      <p class="tTitle1">
        {{$t('refererElement.selectSupport')}}
        <multiselect v-model="item.support" :options="supportList" placeholder="Select one" label="id" track-by="id" ></multiselect>
      </p>
      <p class="tTitle1">
        {{$t('refererElement.selectLeg')}}
        <select name="language_value2" id="language_value2"
                class="lang ng-untouched ng-pristine ng-valid" ng-reflect-name="language_value2"
                v-model='item.select'>
          <option value="L" ng-reflect-value="L">{{$t('common.treeLeft')}}</option>
          <option value="R" ng-reflect-value="R">{{$t('common.treeRight')}}</option>
        </select>
      </p>
      <div class="clearfix"></div>
      <p class="btnBuy motion t_RVN_order"
         style="color: goldenrod; font-weight: 600;" @click="changeSupport(item)">{{$t('common.submit')}}</p>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
const CryptoJS = require("crypto-js");

export default {
  components: {
    Multiselect
  },
  name: 'reffer-element',
  props: {
    item: Object,
    supportList: Array
  },
  methods: {
    changeSupport(item) {
      const id = item.id;
      const support = item.support.id.trim();
      const position = item.select;
      let position_text = '';

      if (position === 'L') {
        position_text = this.$i18n.t('common.treeLeft')
      } else if (position === 'R') {
        position_text = this.$i18n.t('common.treeRight')
      }

      if (support === '') {
        this.$alert(this.$i18n.t('refererElement.notSelectSupport'))
        return false
      }

      const body = {id, position, support};

      this.$confirm(`${this.$i18n.t('refererElement.id')} : ${id} ${this.$i18n.t('refererElement.supporter')} : ${support} ${this.$i18n.t('refererElement.line')} : ${position_text} ${this.$i18n.t('refererElement.perform')}`).then(
          () => {
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),
                process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$axios.post('/member/reffer/ChangeSupport', {req}).then(
                res => {
                  if (res.status === 200) {
                    if (res.data.code === 200) {
                      this.$alert(this.$i18n.t('refererElement.performOk')).then(
                          () => {
                            this.$router.go();
                          }
                      )
                    } else if (res.data.code === 9999) {
                      this.$store.dispatch('SETLOGOUT').then(
                          () => {
                            window.location.href = "/sign/login";
                          }
                      )
                    }
                  }
                }
            )
          }
      ).catch();
    }
  }
}
</script>

<style lang="scss" scoped>

.gBoxN_01 {
  padding: 0;
}

.gBoxN_01 .tTitle {
  padding: 10px 0;
  font-weight: 500;
  border-radius: 10px 10px 0 0;
}

.gBoxN_01 .tTitle1 {
  color: #888;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 15px;
}

.gBoxN_01 .tTitle1 span {
  color: #333;
  font-size: 14px;
}

.gBoxN_01 .tTitle1 select {
  border-color: #ddd;
  border-radius: 15px;
  color: #333;
  margin: 0 -2px;
  background-color: #0000000c;
  text-align: center;
  cursor: pointer;
  -webkit-appearance: none; /* 크롬 화살표 없애기 */
  -moz-appearance: none; /* 파이어폭스 화살표 없애기 */
  appearance: none /* 화살표 없애기 */
}

.card_bottom {
  padding: 15px 10px 10px;
}

select {
  height: 24px;
  width: 70px;
  color: #000;
  border: 1px solid #000;
  margin-top: -4px;
  margin-left: 10px;
}

select option {
  margin: 40px;
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}

@media (min-width: 500px) {
  .gBoxN_01 {
    width: 80%;
    margin-bottom: 20px;
  }
}

@media (min-width: 700px) {
  .gBoxN_01 {
    width: 85%;
    margin-bottom: 20px;
  }
}

</style>